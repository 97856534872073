import {translator} from "@/lib/translator";
export default {
name: "motorMixin",
    computed:{

    },
    methods:{

      expand_motorcocde(motors){

        for(var i=0; i< motors.length; i++){
          let m = motors[i]
          if (!m.motor_id){

            m.typ = m.code.substring(37, 40).replace(/_/g,'');
            m.power = parseInt(m.code.substring(31, 37).replace(/_/g,''))/100;
            m.pole = m.code.substring(29, 30).replace(/_/g,'');

            m.weight  = m.code.substring(75, 78).replace(/_/g,'');

            m.manufacturer = m.code.substring(2, 12).replace(/_/g,'');
            m.productline= m.code.substring(12, 22).replace(/_/g,'');
            m.axisheight= m.code.substring(22, 25).replace(/_/g,'');
            m.shape = m.code.substring(25, 28).replace(/_/g,'');// kostry
            m.voltage= m.code.substring(40, 43).replace(/_/g,'');
            m.frequency= m.code.substring(46, 48).replace(/_/g,'');
            m.color= m.code.substring(72, 76).replace(/_/g,'');

            m.bearingDE= m.code.substring(50, 55).replace(/_/g,'');
            m.bearingDEClearance= m.code.substring(55, 57).replace(/_/g,'');
            m.bearingDESealing= m.code.substring(57, 61).replace(/_/g,'');

            m.bearingNDE= m.code.substring(61, 66).replace(/_/g,'');
            m.bearingNDEClearance= m.code.substring(66, 68).replace(/_/g,'');
            m.bearingNDESealing= m.code.substring(68, 72).replace(/_/g,'');

            m.sn = m.code.substring(80, 90);
            m.companyID = m.code.substring(90, 100);
          }
        }
        return motors
      },


     $t: translator,
        classBarvaKolecka(alert_level){


            if (alert_level>=2) return   "text-danger"
            if (alert_level==1) return   "text-warning"
            // if (this.motor.vStateColor == 'Red' || this.motor.tStateColor=='Red') return "text-danger"

            return "text-success"
        },




        vibrImageUrl(motor){
    //            const imgs= ['/img/VibrAlarmGray.png','/img/VibrAlarmGold.png','/img/VibrAlarmRed.png']
            if (motor && motor.states!=undefined){
            //const imgs= ['/img/TempAlarmGray.png', '/img/TempAlarmGold.png', '/img/TempAlarmRed.png']
                if (motor.states.vStateColor) {
                    let c= motor.states.vStateColor
                    if (c=='Red'  || c=='Gray' || c=='Gold'){
                        return `/img/VibrAlarm${c}.png`
                    }
                }
            }
            return '/img/VibrAlarmGray.png'
        },
        tempImageUrl(motor){
            if (motor && motor.states!=undefined){
            //const imgs= ['/img/TempAlarmGray.png', '/img/TempAlarmGold.png', '/img/TempAlarmRed.png']
                if (motor.states.tStateColor) {
                    let c= motor.states.tStateColor
                    if (c=='Red'  || c=='Gray' || c=='Gold'){
                        return `/img/TempAlarm${c}.png`
                    }
                }
            }
            return '/img/TempAlarmGray.png'
        },

        lubrImageUrl(motor){
            if (motor.notifications == undefined ) return ""

            for(var i in motor.notifications){
                let nf =  motor.notifications[i];
                if (nf.active && nf.message_code=='m.notice.need-lubrication'){
                    return "/img/LubrAlarmGold.png"
                }
            }
            return "/img/LubrAlarmGray.png"
        },

        maintanceImageUrl(motor){
            if (motor.notifications == undefined ) return ""

            for(var i in motor.notifications){
                let nf =  motor.notifications[i];
                if (nf.active && nf.message_code=='m.notice.need-maintance'){
                    return "/img/ServiceAlarmGold.png"
                }
            }
            return "/img/ServiceAlarmGray.png"
        },


        measurementImageUrl(motor){
            if (motor.notifications == undefined ) return ""

            for(var i in motor.notifications){
                let nf =  motor.notifications[i];
                if (nf.active && nf.message_code=='m.notice.need-measurement'){
                    return "/img/MeasAlarmGold.png"
                }
            }
            return "/img/MeasAlarmGray.png"
        }

    }
}
